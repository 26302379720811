import apiveiculoweb from '@/services/api-recebimentoweb';

export default {
    namespaced: true,

    state: {
    list: [],
    },

    getters: {
    list: (state) => state.list,
    },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
    actions: {

    // CONSULTA SEM CACHE
    async updateUserId(_, { key, userid }) {
        const { data } = await apiveiculoweb.put(`/webpush/key/${key}/${userid}`);
        return data;
    },
},

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
mutations: {
    SET_LIST(state, list) {
        state.list = list ?? [];
    },
},};
