/* eslint-disable no-console */

import { register } from 'register-service-worker';

import SnackbarEvent from '@/events/SnackbarEvent';

import db from "@/plugins/dexie";

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered(registration) {
      console.log('Service worker has been registered.');

      setTimeout(() => {
        setWorkerStorage();
      }, 1000);
      // Subscribe to push notifications
      if ('PushManager' in window) {
        checkExistingSubscription(registration);
      } else {
        console.warn('Push messaging is not supported in this browser.');
      }
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      console.log('New content is available; please refresh.');

      const { waiting } = registration;
      if (!waiting) return;

      document.dispatchEvent(new SnackbarEvent({
        message: 'App atualizado, recarregando',
        icon: 'mdi-refresh',
        closeable: false,
        timeout: 6000,
        // action: {
        //   text: i18n.t('pwa.update'),
        //   handler: () => {
        //     waiting.postMessage({
        //       type: 'SKIP_WAITING',
        //     });
        //   },
        // },
      }));

      setTimeout(() => {
        waiting.postMessage({
          type: 'SKIP_WAITING',
        });
      }, 2000);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}

let refreshing = false;
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return;

  refreshing = true;
  window.location.reload();
});

async function checkExistingSubscription(registration) {
  try {
    const existingSubscription = await registration.pushManager.getSubscription();
    if (existingSubscription) {
      console.log('User is already subscribed.');
    } else {
      console.log('No existing subscription found, creating a new one.');
      subscribeUserToPush(registration);
    }
  } catch (error) {
    console.error('Error checking existing subscription:', error);
  }
}

async function subscribeUserToPush(registration) {
  const vapidPublicKey = 'BOBSMlu2YHatqAa32QziaDTzGm78tKLgbkKZc8oWp7Phq7HiXX19koEjUz_GzFk0sZHko6Tn7vHCl2MGFXAHdfI';
  const applicationServerKey = urlBase64ToUint8Array(vapidPublicKey);

  try {
    const response = await fetch(`${window.location.origin}/api-base-url`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the API base URL');
    }

    const apiBaseUrl = await response.text();
    console.log('API Base URL:', apiBaseUrl);

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    });

    console.log('Push subscription:', subscription);

    await fetch(`${apiBaseUrl}/webpush/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(subscription),
    });

    db.worker.update(1, {
      worpushkey: (JSON.parse(JSON.stringify(subscription))).keys.p256dh,
    })
    

    console.log('Subscription successfully sent to the server.');
  } catch (error) {
    console.error('Failed to subscribe to push notifications:', error);
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i++) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function setWorkerStorage() {
  const response = await fetch(`${window.location.origin}/api-base-url`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch the API base URL');
  }

  const apiBaseUrl = await response.text();
  console.log('API Base URL:', apiBaseUrl);


    try {

      const item = await db.worker.get({ id: 1 });

      if (item.wortoken) {
        return;
      }

      const workerData = {
        id: 1,
        worapibaseurl: apiBaseUrl,
        wortoken: JSON.parse(sessionStorage.getItem('auth:token')),
      };
      await db.worker.put(workerData);
      console.log(workerData);
    } catch (e) {
      console.log(`e: ${e}`);
    }
  }
